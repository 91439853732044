import React from 'react'

export default function Logo() {
    return (
        <svg className="logosvg ab-l-m m-l-15 trans-0-2" width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="17" cy="17" r="17" fill="url(#paint0_linear)"/>
            <path d="M20.84 15.86C20.84 15.2967 21.2967 14.84 21.86 14.84H23.82C24.3833 14.84 24.84 15.2967 24.84 15.86V23.82C24.84 24.3833 24.3833 24.84 23.82 24.84H21.86C21.2967 24.84 20.84 24.3833 20.84 23.82V15.86Z" fill="white"/>
            <path d="M20.84 10.84C20.84 9.73543 21.7354 8.84 22.84 8.84C23.9446 8.84 24.84 9.73543 24.84 10.84C24.84 11.9446 23.9446 12.84 22.84 12.84C21.7354 12.84 20.84 11.9446 20.84 10.84Z" fill="white"/>
            <path d="M8.84003 9.86C8.84003 9.29667 9.2967 8.84 9.86003 8.84H11.82C12.3834 8.84 12.84 9.29667 12.84 9.86V23.82C12.84 24.3833 12.3834 24.84 11.82 24.84H9.86003C9.2967 24.84 8.84003 24.3833 8.84003 23.82V9.86Z" fill="white"/>
            <path d="M13.9006 20.4263C13.7117 19.9074 13.9793 19.3335 14.4982 19.1446L16.3776 18.4606C16.8966 18.2717 17.4704 18.5393 17.6593 19.0582L19.0274 22.817C19.2163 23.336 18.9487 23.9098 18.4297 24.0987L16.5504 24.7828C16.0314 24.9717 15.4575 24.7041 15.2686 24.1851L13.9006 20.4263Z" fill="white"/>
            <path d="M15.2686 9.49825C15.4575 8.97927 16.0314 8.71169 16.5504 8.90058L18.4297 9.58462C18.9487 9.77351 19.2163 10.3474 19.0274 10.8663L17.6593 14.6251C17.4704 15.1441 16.8966 15.4117 16.3776 15.2228L14.4982 14.5387C13.9793 14.3498 13.7117 13.776 13.9006 13.257L15.2686 9.49825Z" fill="white"/>
            <defs>
            <linearGradient id="paint0_linear" x1="17" y1="0" x2="17" y2="34" gradientUnits="userSpaceOnUse">
            <stop stopColor="#0066FF"/>
            <stop offset="1" stopColor="#5498FF"/>
            </linearGradient>
            </defs>
        </svg>
    )
}
