import React from 'react'

export default function Explorer() {
    return (
        <svg className="ab-l-m m-l-13" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.01438 16.9999C8.12235 16.9996 8.22957 16.9819 8.33188 16.9474L14.3319 14.9474C14.4787 14.898 14.612 14.8151 14.7214 14.7053C14.8307 14.5956 14.913 14.4619 14.9619 14.3149L16.9619 8.31489C17.0206 8.1387 17.0291 7.94962 16.9865 7.76885C16.9438 7.58808 16.8517 7.42276 16.7203 7.29143C16.589 7.16009 16.4237 7.06793 16.2429 7.02527C16.0621 6.98261 15.8731 6.99114 15.6969 7.0499L9.69687 9.04989C9.55005 9.09932 9.41671 9.18218 9.30738 9.29194C9.19805 9.4017 9.11572 9.53537 9.06688 9.68239L7.06688 15.6824C7.01655 15.8327 7.00268 15.9928 7.02639 16.1495C7.05011 16.3062 7.11074 16.4551 7.20328 16.5837C7.29582 16.7124 7.41763 16.8173 7.55865 16.8896C7.69967 16.962 7.85588 16.9998 8.01438 16.9999V16.9999ZM10.8044 10.7899L14.4344 9.57989L13.2244 13.2099L9.59437 14.4199L10.8044 10.7899Z" fill="#0066FF" />
            <path d="M12.0145 24C14.791 23.9966 17.4804 23.0306 19.6245 21.2664C21.7685 19.5023 23.2345 17.0492 23.7725 14.3253C24.3106 11.6014 23.8874 8.77523 22.5751 6.32838C21.2628 3.88154 19.1426 1.96547 16.5759 0.906709C14.0092 -0.152052 11.1547 -0.287983 8.49895 0.522083C5.84322 1.33215 3.55057 3.03808 2.01171 5.34915C0.472853 7.66022 -0.216977 10.4334 0.0597817 13.1961C0.33654 15.9588 1.56276 18.5401 3.52947 20.5C4.64371 21.6132 5.96684 22.4955 7.42292 23.0961C8.87901 23.6967 10.4394 24.0039 12.0145 24V24ZM4.94196 4.92999C6.34037 3.53116 8.12219 2.57846 10.0621 2.19236C12.002 1.80626 14.0128 2.00412 15.8402 2.76091C17.6677 3.51769 19.2296 4.79941 20.3286 6.44397C21.4275 8.08852 22.0141 10.022 22.0141 12C22.0141 13.9779 21.4275 15.9114 20.3286 17.556C19.2296 19.2005 17.6677 20.4823 15.8402 21.239C14.0128 21.9958 12.002 22.1937 10.0621 21.8076C8.12219 21.4215 6.34037 20.4688 4.94196 19.07C4.01042 18.1433 3.27119 17.0416 2.76676 15.8283C2.26234 14.615 2.00267 13.314 2.00267 12C2.00267 10.686 2.26234 9.38496 2.76676 8.17165C3.27119 6.95835 4.01042 5.85669 4.94196 4.92999V4.92999Z" fill="#0066FF" />
        </svg>

    )
}
