import React from 'react'

export default function Farmsvg() {
    return (
        <svg width={24} height={24} className="ab-l-m m-l-13" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_1765_4093)">
                <path d="M7.91797 22.7931H16.0821L11.9999 19.0938L7.91797 22.7931Z" fill="#666666" />
                <path d="M15.6561 14.5459H8.34424L12.0002 17.8596L15.6561 14.5459Z" fill="#666666" />
                <path d="M14.4773 7.31885H9.52246V10.4014H14.4773V7.31885Z" fill="#666666" />
                <path d="M11.9553 2.91309L4.79966 5.92269L1.60742 13.6828C1.61774 13.7268 1.62518 13.7709 1.62518 13.8158V22.2708C1.62518 22.5578 1.86014 22.7932 2.14766 22.7932H6.73814V14.128C6.73814 13.8972 6.9251 13.7102 7.15622 13.7102H16.8433C17.0742 13.7102 17.2612 13.8972 17.2612 14.128V22.793H21.8519C22.1389 22.793 22.3744 22.5578 22.3744 22.2705V13.7983C22.3744 13.782 22.3777 13.7654 22.3794 13.7493L19.1161 5.91189L11.9553 2.91309ZM15.3129 10.8196C15.3129 11.0505 15.1259 11.2377 14.895 11.2377H9.1043C8.87342 11.2377 8.68622 11.0505 8.68622 10.8196V6.90093C8.68622 6.67005 8.87342 6.48285 9.1043 6.48285H14.8948C15.1257 6.48285 15.3126 6.67005 15.3126 6.90093V10.8196H15.3129Z" fill="#666666" />
                <path d="M16.4256 21.8707V15.0823L12.6807 18.4764L16.4256 21.8707Z" fill="#666666" />
                <path d="M7.57422 21.8704L11.3192 18.4764L7.57422 15.0825V21.8704Z" fill="#666666" />
                <path d="M23.9652 12.971L20.7778 5.24807C20.623 4.87295 20.2239 4.47263 19.849 4.31687L12.6495 1.32407C12.2743 1.16807 11.7084 1.16831 11.3333 1.32503L4.13834 4.32863C3.7637 4.48487 3.36506 4.88591 3.21074 5.26127L0.0343375 12.9895C-0.0614225 13.223 0.0499375 13.4901 0.283457 13.5859C0.340337 13.6094 0.399137 13.6205 0.457217 13.6205C0.583937 13.6205 0.703938 13.5655 0.790578 13.4724C0.826818 13.4333 0.858738 13.3893 0.880338 13.3373L0.958578 13.1469L4.0565 5.60879C4.11914 5.45615 4.33826 5.23607 4.49066 5.17247L11.6856 2.16863C11.8373 2.10527 12.1464 2.10527 12.2986 2.16815L18.0334 4.55207L19.524 5.17631C19.6193 5.22383 19.7297 5.32007 19.8166 5.42351C19.8183 5.42687 19.8214 5.42879 19.8226 5.43215L23.0278 13.1318L23.0415 13.1289L23.1204 13.32C23.2162 13.5533 23.4826 13.6653 23.7173 13.5679C23.9508 13.4719 24.0617 13.2045 23.9652 12.971Z" fill="#666666" />
            </g>
            <defs>
                <clipPath id="clip0_1765_4093">
                    <rect width={24} height={24} fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}




